<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>
  </Layout>
</template>